@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);

*{
  --green: #40ab50; 
}

body{
  background-color: black;
  color: white;
  font-family: Montserrat;
}

h1, h2, h3, h4, h5, h6{
  font-family: Roboto;
}

/* h1{
  font-size: calc(1.925rem + .9vw);
}

h2{
  font-size: calc(.225rem + .9vw);
}

h3{
  font-size: calc(.125rem + .9vw);
} */

.green-color{
  color: var(--green);
}

.white-color{
  color: #FFFFFF;
}

.banner{
  width: 100%;
}

hr{
    position: relative;
    /* top: 20px; */
    border: none;
    height: 5px !important;
    background-color: var(--green);
    margin: 0;
    /* margin-bottom: 50px; */
    opacity: 1 !important;
}

a{
  text-decoration: none !important;
  color: white !important;
}

.subtitulo{
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
  padding: 20px 0;
  font-size: 1.3em;
}